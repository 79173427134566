/* Buttons */
.modal-trigger{
    margin:4px
}

.spacer{
    
    width:100%;
}

.package{
   
}