
.card{
    width:300px;
}
.gallery-container{
    margin:0px;
    padding:0px;
    width: 100%;
}
.header{
    height:100%;
    padding-bottom:100px;
}
section {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
   
    width: 100%
}
.card-edit{
    width:100%;
}
input{
    width:100%;
}

.headline{
    margin-left: 15px;
}
.edit-form{
    margin:0px 15px 0px 15px;
}
.publish{
    margin-top:10px;
}
.group{
    float:right;
}

