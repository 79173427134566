.location-badge {
    margin:3px
  }

  .item-card-tag-container{
    margin: 3px 10px 3px 3px;
    margin-top:10px;
  }
  .item-card-location-container{
    margin: 3px;
  }
  .item-list{
    font-size:12px;
  }

  .add-beer-button {
    font-size:10px;
    float: right;
  }
  .content-card {
   padding: 0px;
   margin: 0px;
  }
  .section-tools{
    border-style: solid;
    border-radius: 3px;
    border-width: 1px;
    color: #c0c0c0;
    padding: 5px;
  }
  .badge-button{
    -webkit-box-shadow: -3px 2px 9px -5px rgba(0,0,0,0.29);
    -moz-box-shadow: -3px 2px 9px -5px rgba(0,0,0,0.29);
    box-shadow: -3px 2px 9px -5px rgba(0,0,0,0.29);
    transition: 0.5s;
  }
  .badge-button:hover {
    -webkit-box-shadow: -3px 2px 9px -5px rgba(0,0,0,0.29);
    -moz-box-shadow: -3px 2px 9px -5px rgba(0,0,0,0.29);
    box-shadow: -3px 2px 9px -5px rgba(0,0,0,0.29);
    margin-top: -1.5px;
    color: #4593a8;
    transition: 0.5s;
  }

  .untappd-beer-list {
    transition: all .2s ease-in-out;
  }

  .untappd-beer-list:hover{
    transform: scale(1.05);
    margin:2px;
    color: #d4391d
  }

  .untappd-list-badge {
    transition: all .2s ease-in-out;
  }

  .untappd-list-badge:hover {
    color:white
  }