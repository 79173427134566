.card{
  width:100%
}
.dynamicInput{
  margin-top:5px
}

input[type="text"].formInput::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-size: 12px
}
input[type="text"].formInput::-moz-placeholder { /* Firefox 19+ */
  font-size: 12px
}
input[type="text"].formInput:-ms-input-placeholder { /* IE 10+ */
  font-size: 12px
}
input[type="text"].formInput:-moz-placeholder { /* Firefox 18- */
  font-size: 12px
}

